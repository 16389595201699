.join-users-container {
    text-align: center;
    padding: 100px 20px;
}

.headline {
    font-size: 3rem;
    font-weight: 700;
    /* color: #651c81; */
    color: #8d41ab;
    margin: 0;
}

.highlighted-text {
    text-decoration: underline;
    text-decoration-color: #f2a400;
}

.subtext {
    margin: 20px 0;
    color: #666;
}

.cta-button {
    background-color: #951dc1;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}

.cta-button:hover {
    background-color: #7d18a1;
}


@media (max-width: 768px) {
    .headline {
        font-size: 3rem;
    }

    .cta-button {
        padding: 12px 25px;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .join-users-container {
        padding: 50px 20px;
    }

    .headline {
        font-size: 2.5rem;
    }

    .cta-button {
        padding: 10px 20px;
        font-size: 0.8rem;
    }

    .subtext {
        font-size: 0.9rem;
    }
}