.back-next-btn-cont {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.MuiButtonBase-root.MuiButton-root.outlined-btn,
.MuiButtonBase-root.MuiButton-root.contained-btn {
    text-transform: capitalize;
    width: 80px;
    font-weight: 500;
}

.MuiButtonBase-root.MuiButton-root.outlined-btn {
    color: #951dc1;
    border: 1px solid #951dc1;
}

.MuiButtonBase-root.MuiButton-root.contained-btn {
    color: white;
    background-color: #951dc1;
}