.LoginRegisterHeader_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    height: 80px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.034);
    background-color: white;
}

.LoginRegisterHeader_button {
    padding: 8px 15px;
    background: linear-gradient(90deg, rgba(67, 0, 133, 1) 0%, rgba(164, 35, 204, 1) 0%, rgba(67, 0, 133, 1) 100%, rgba(164, 35, 192, 1) 100%);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: "pointer";
}