
/* App container */
.app-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #651c81;
  color: white;
  padding: 20px;
  /* Add padding for smaller screens */
}

/* Content wrapper */
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
}

/* Left-side text section */
.text-section {
  flex: 1;
}

.text-section h4 {
  color: orange;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.text-section h1 {
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.text-section p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
}

/* Buttons styling */
.buttons {
  display: flex;
  gap: 15px;
}

/* .primary-button {
  background-color: #951dc1;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
} */

.primary-button {
  background-color: #951dc1;
  color: white;
  border: none;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}


.secondary-button {
  background-color: transparent;
  color: #951dc1;
  border: 2px solid #951dc1;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.secondary-button-2 {
  background-color: transparent;
  color: white;
  /* border: 2px solid #951dc175; */
  border: 2px solid #ffffffb5;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

/* Right-side resume section */
.resume-section {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.resume {
  position: absolute;
  width: 250px;
  height: auto;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.resume-1 {
  top: 20px;
  left: 200px;
  transform: rotate(-5deg);
}

.resume-2 {
  top: 80px;
  left: 120px;
  z-index: 1;
}

.resume-3 {
  top: 140px;
  left: 20px;
  transform: rotate(5deg);
  z-index: 2;
}

/* Responsive Design */

/* Adjust layout for smaller screens (1200px and below) */
@media (max-width: 1200px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .resume-section {
    position: relative;
    justify-content: center;
    margin-top: 30px;
  }

  .resume {
    width: 200px;
    /* Reduce size of the resume images */
  }

  .resume-1 {
    top: 10px;
    left: 80px;
  }

  .resume-2 {
    top: 50px;
    left: -5px;
  }

  .resume-3 {
    top: 100px;
    left: -100px;
  }

  .text-section h1 {
    font-size: 34px;
  }

  .text-section p {
    font-size: 14px;
  }
}

/* Tablet View (768px and below) */
@media (max-width: 768px) {
  .text-section h1 {
    font-size: 28px;
    text-align: center;
  }

  .text-section p {
    font-size: 14px;
    text-align: center;
  }

  .buttons {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .resume-section {
    justify-content: center;
  }

  .resume {
    width: 150px;
    /* Further reduce resume image size */
  }

  .resume-1 {
    top: 0px;
    left: 60px;
  }

  .resume-2 {
    top: 50px;
    left: -10px;
  }

  .resume-3 {
    top: 100px;
    left: -100px;
  }
}

/* Mobile View (480px and below) */
@media (max-width: 480px) {
  .content {
    width: 100%;
  }

  .text-section h1 {
    font-size: 24px;
    text-align: center;
  }

  .text-section p {
    font-size: 12px;
    text-align: center;
  }

  .buttons {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .resume-section {
    justify-content: center;
  }

  .resume {
    width: 130px;
  }

  .resume-1 {
    top: 0px;
    left: 50px;
  }

  .resume-2 {
    top: 40px;
    left: -10px;
  }

  .resume-3 {
    top: 80px;
    left: -80px;
  }
}