.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  height: 80px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.034);
  position: sticky;
  /* Keeps the navbar fixed at the top */
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s ease;
  z-index: 100;
}

.navbar.hidden {
  transform: translateY(-100%);
}

.navbar.visible {
  transform: translateY(0);
}

.navbar-left {
  display: flex;
  align-items: center;
}

.logo {
  width: 150px;
  height: auto;
}

.brand {
  font-size: 20px;
  font-weight: bold;
  color: #1f2937;
}

.brand-subtext {
  font-size: 10px;
  margin-left: 5px;
  color: #6b7280;
}

.navbar-center {
  display: flex;
  align-items: center;
  gap: 25px;
}

.navbar-links {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 0px !important;
}

.navbar-center ul {
  /* margin-top: 16px; */
  margin-bottom: 1rem;
}

.navbar-links li {
  position: relative;
  padding: 24px 0px;
}

.navbar-links a {
  text-decoration: none;
  font-size: 18px;
  color: #4b5563;
  /* font-weight: 500; */
}

.navbar-right a {
  font-weight: 500;
  color: #951dc1;
}

.my-account {
  font-size: 16px;
  color: #4b5563;
  text-decoration: none;
  margin-right: 20px;
}

.btn-build-resume {
  background-color: #951dc1;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
}

.btn-build-resume:hover {
  background-color: #7d18a1;
}

.dropdown {
  position: static !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  position: absolute;
  top: 92px;
  /*right: -560px; */
  left: 0px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: auto;
  margin: 0px 30px;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  z-index: 10;
}

.dropdown-content a {
  color: #4b5563;
  padding: 5px 0px;
  text-decoration: none;
  display: block;
}

.mega-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in;
}

.dropdown:hover .mega-menu {
  display: block;
}

.resume-templates h5 {
  margin-left: 25px;
}

.resume-templates,
.resume-examples,
.resume-tools {
  flex: 1;
}


.resume-templates ul,
.resume-examples ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.resume-templates li,
.resume-examples li {
  width: 35%;
  box-sizing: border-box;
  padding: 10px;
}

.resume-templates li a,
.resume-examples li a {
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.resume-templates li p,
.resume-examples li p {
  margin: 5px 0 15px;
  color: #6b7280;
  font-size: 16px;
}


.view-all,
.all-examples {
  display: block;
  margin-top: 10px;
  color: black;
  font-weight: bold;
  text-decoration: none;
}

/* Mobile Menu (initially hidden) */
.hamburger {
  display: none;
  cursor: pointer;
}

.menu {
  display: none;
}

@media (max-width: 850px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    height: 80px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s ease;
    z-index: 100;
  }

  .navbar-links {
    flex-direction: column;
    gap: 10px;
    display: none;
  }

  .hamburger {
    display: block;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 200;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    width: 60%
  }

  .menu.active {
    display: flex;
    transform: translateX(0);
    padding: 0px;
  }

  .navbar-center ul {
    margin-top: 10px;
    margin-bottom: 1rem;
  }

  .menu li {
    padding: 18px 25px;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    list-style-type: none;
  }

  .menu li:last-child {
    border-bottom: none;
  }

  .menu a {
    color: #333;
  }
}

.nav-link-btn {
  color: #1e2532 !important;
  /* font-weight: 700 !important; */
  transition: all 0.5s;
}

.nav-link-btn:hover {
  color: #6d168d !important;
  transition: all 0.5s;
}

.nav-link-btn-active {
  color: #6d168d !important;
}