/* section 1 ------------------------------------------------------------------------- */
.main-div-newsroom {
  /* border: 1px solid black */
  padding: 0px 150px;
}

.news-section-1 {
  display: flex;
}

.newsroom-1 {
  position: relative;
}

.newsroom-1::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40%;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
  z-index: 1;
}

.img-newsroom {
  height: 380px;
  width: 100%;
  object-fit: cover;
  display: block;
}

.text-block {
  position: absolute;
  bottom: 0%;
  width: 100%;
  /*top: 38%;
  left: 20px;
  padding-left: 10px;*/
  padding: 10px;
  z-index: 2;
}

.text-block span {
  font-weight: 500;
  font-size: 18px;
  color: white;
}

.news-text1 {
  color: white;
  font-weight: 400;
  font-size: 20px;
  /* width: 60%; */
  line-height: normal;
  cursor: pointer;
  transition: 0.5s ease;
}

.text-block p {
  font-weight: 400;
  font-size: 18px;
  color: white;
}

.newsroom-1:hover .overlay {
  height: 20%;
}

.newsroom-1:hover .news-text1 {
  color: #a24bc7;
  transition: 0.5s ease;
  text-decoration: underline;
}

.news-card {
  position: relative;
}

.news-card::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40%;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
  z-index: 1;
}

.news-card-img {
  height: 181px;
  object-fit: cover;
  width: 100%;
}

.card-text-block {
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 2;
}

.card-news-text1 {
  color: white;
  font-weight: 500;
  font-size: 15px;
  width: 100%;
  line-height: normal;
  cursor: pointer;
  transition: 0.5s ease;
}

.news-card:hover .card-news-text1 {
  color: #a24bc7;
  transition: 0.5s ease;
  text-decoration: underline;
}


.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #a24bc7;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* section 2 ------------------------------------------------------------------------- */
.trending-div {
  height: auto;
}

.trd-img {
  width: 100%;
  height: 185px;
  object-fit: cover;
}

.trd-text-1 {
  display: flex;
  justify-content: space-between;
}

.trd-text-2 {
  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: normal;
  cursor: pointer;
}

.trending-div:hover .trd-text-2 {
  color: #a24bc7;
  transition: 0.5s ease;
  /* text-decoration: underline; */
}

/* section 3 ----------------------------------------------------------- */
.weekly-review {
  height: auto;
  /* width: 350px; */
  width: auto;
}

.weekly-reviews-img {
  width: 100%;
  height: 450px !important;
  border-radius: 10px !important;
  object-fit: cover;
}

.review-text-main {
  /* border-radius: 0px 0px 10px 10px; */
  border: 1px solid rgb(238, 242, 242);
}

.review-text-1 {
  display: flex;
  justify-content: space-between;
}

.review-text-2 {
  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: normal;
  cursor: pointer;
}

.weekly-review:hover .review-text-2 {
  color: #d1c6d6;
  transition: 0.5s ease;
  /* text-decoration: underline; */
}

/* -newww------------- */

.blog-parent {
  display: flex;
  gap: 10px;
  height: 500px;
}

@media (max-width: 900px) {
  .blog-parent {
    display: block;
    height: auto;
  }

  .blog-child2 {
    margin: 15px 0;
  }
}

.blog-child1 {
  flex: 2;
  min-width: 400px;
  height: 500px;
  background-color: #f0ece5;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.blog-child2 {
  flex: 1;
  min-width: 150px;
  height: auto;
  padding: 0px 20px;
}

/* .blog-child3 {
  flex: 1;
  min-width: 150px;
  height: 600px;
  overflow-y: scroll;
  padding: 0px 0px;
} */
.blog-child3 {
  flex: 1;
  min-width: 150px;
  height: 600px;
  overflow-y: auto;
  /* padding: 10px; */
  scrollbar-width: thin;
  scrollbar-color: #ccc #f1f1f1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}



.blog-child1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-child1 .blog-overlay-text {
  position: absolute;
  top: 23%;
  left: 36%;
  transform: translate(-50%, -50%);
  /* color: white; */
  font-size: 1.2em;
  /* font-weight: bold; */
  /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); */
  /* text-align: center; */
  line-height: 14px;
}

.blog-overlay-text p {
  font-size: 16px;
  align-items: center;
  color: rgb(15 20 30 / 50%);
}

.blog-overlay-text p span {
  font-weight: 500;
  color: black;
}

.blog-overlay-text h3 {
  font-size: 28px;
}

.blog-child1 img {
  height: 251px;
  width: 288px;
}

.blog-child2 img {
  border-radius: 10px;
  height: 200px;
  width: 288px;
  background-color: #2e365d;
  object-fit: cover;
}

.blog-child2 p {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.blog-child2 span {
  font-size: 14px;
  font-weight: 500;
  color: rgb(15 20 30 / 50%);
}

.blog-child3 span {
  font-size: 14px;
  font-weight: 500;
  color: rgb(15 20 30 / 50%);
}

.blog-child3 p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

/* ------------------------------------carosel-------------------------------------------------------------- */

/* BreakingNewsSlider.css */

.breaking-news-slider {
  position: relative;
  width: 100%;
  /* background-color: #595858; */
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

.slider-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
}

.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  /* color: #ffcc00; */
  color: #951dc1;
  /* Color of the navigation dots */
}

.slider-item {
  padding: 10px;
  font-size: 18px;
  background-color: #444;
  border-radius: 5px;
  margin: 5px 0;
}

.news-image {
  width: 100%;
  /* Adjust the width as needed */
  height: 380px;
  /* Maintain aspect ratio */
  border-radius: 5px;
  margin-bottom: 15px;
}

.news-item {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
}

.news-item:hover {
  color: #951dc1;
  /* Hover effect */
  transition: color 0.3s ease;
}

/* ---------------------------browse all section css */

.browse-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}


@media (max-width: 768px) {
  .browse-cards-container {
    grid-template-columns: repeat(2, 1fr);
    /* 2 cards on medium screens */
  }
}

@media (max-width: 480px) {
  .browse-cards-container {
    grid-template-columns: 1fr;
    /* 1 card on small screens */
  }
}

.browse-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.browse-card:hover {
  transform: scale(1.03);
}

.browse-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.browse-card-content {
  padding: 15px;
}

.browse-card-meta {
  color: #6c757d;
  font-size: 14px;
  margin-bottom: 5px;
}

.browse-card-title {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  transition: color 0.125s ease;
}

.browse-card-title:hover {
  color: #ff5733;
}