footer {
  width: 100%;
  position: relative;
  height: auto;
  background-color: #0d1117;
  color: gray;
  padding: 30px 0px;
}

.footer-icons {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 5px;
  color: White;
}

.footer-icons li {
  border: 1px solid white;
  border-radius: 100%;
  padding: 3px;
}

.footer-icons svg {
  padding: inherit;
}

.footer-logo {
  color: White;
}

.footer a {
  text-decoration: none;
  /* color: rgba(255, 255, 255, 0.65); */
  color: rgb(255 255 255 / 87%);
}

footer a:hover {
  text-decoration: underline;
}

.footer .footer-col {
  width: 230px;
  height: auto;
  float: left;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0px 20px 20px 20px;
}

.footer .footer-col h1 {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 12px;
  line-height: 17px;
  padding: 20px 0px 5px 0px;
  footer-color: rgba(255, 255, 255, 0.2);
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}

.footer .footer-col ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list-unstyled {
  footer-color: #999999;
  font-size: 14px;
  font-family: inherit;
  font-weight: bold;
  padding: 3px;
  cursor: pointer;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  line-height: 28px;
}

.social ul li {
  display: inline-block;
  padding-right: 5px !important;
}

.footer .footer-col ul li:hover {
  footer-color: #ffffff;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
}

.footer-col i,
.footer .social-icons {
  font-size: 35px;
}

.clearfix {
  clear: both;
}



.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  /* Dark background */
  color: #ffffff;
  /* White text */
  /* font-family: Arial, sans-serif; */
  font-size: 14px;
}

.footer-left-bottom,
.footer-center-bottom,
.footer-right-bottom {
  display: flex;
  align-items: center;
  gap: 8px;
}

.flag-bottom {
  font-size: 20px;
}

.badge-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333333;
  padding: 5px 10px;
  border-radius: 4px;
}

.footer-right-bottom {
  text-align: right;
}

.brand-bottom a {
  font-weight: bold;
  color: #951dc1;
}

.footer-bottom-links {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: inline-flex;
  gap: 15px;
}

@media only screen and (min-width: 1280px) {
  .footer-container {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0px;
  }
}

@media only screen and (max-width: 1139px) {
  .footer-container .social {
    width: 1000px;
    display: block;
  }

  .social h1 {
    margin: 0px;
  }
}

@media only screen and (max-width: 950px) {
  .footer .footer-col {
    width: 33%;
  }

  .footer .footer-col h1 {
    font-size: 14px;
  }

  .footer .footer-col ul li {
    font-size: 13px;
  }
}

@media only screen and (max-width: 500px) {
  .footer .footer-col {
    width: 50%;
  }

  .footer .footer-col h1 {
    font-size: 14px;
  }

  .footer .footer-col ul li {
    font-size: 13px;
  }
}

@media only screen and (max-width: 340px) {
  .footer .footer-col {
    width: 100%;
  }
}

@media (max-width: 1200px) {}

@media (max-width: 768px) {
  .footer-bottom {
    visibility: hidden;
  }
}

/* mobile screen ---------------------------------------------------------------------------------------------------- */
@media (max-width: 480px) {
  .footer-bottom {
    visibility: hidden;
  }
}