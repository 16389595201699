.template-header {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  padding: 20px 0px 15px 0px;
  gap: 5px;
}

.template-header-first {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  padding-left: 10px;
}

.template-image-comp {
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background-color: rgb(193, 193, 193);
  display: flex;
  /* justify-content: center;
  align-items: center; */
  overflow: hidden;
  text-align: center;
  background: no-repeat;
}



.template-img-text {
  width: 100%;
  padding-top: 15px;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 1px;
}

.template-user-details-comp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 25px;
}

.template-image-comp .template-profile-img {
  width: 100px;
}

.template-user-name {
  line-height: 30px;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 600;

}

.template-user-designation {
  font-size: 15px;
  font-weight: 600;
}

.template-header-address {
  width: 20%;
  text-align: left;
  font-size: 12px;
  /* word-wrap: normal; */
  font-weight: 500;
}

.template-header-second {
  width: 20%;
  text-align: left;
  font-size: 12px;
  word-wrap: normal;
  font-weight: 500;
}

.template-user-objective {
  font-size: 12px;
  padding: 0px 24px;
  /* height: 25px; */
  /* overflow-y: scroll; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}





@media screen and (max-width: 900px) {
  .template-user-name {
    font-size: 5vw;
    font-weight: 600;
  }

  .template-img-text {
    font-size: 4vw;
    border-radius: 50%;
    padding-top: 16px;
  }

  .template-user-objective {
    font-size: 2.5vw;
  }


  .template-header-second {
    font-size: 2vw;
  }


  .template-user-details-comp {
    margin-left: 15px;
  }

  .template-image-comp {
    width: 20%;
    height: 20%;
  }
}



@media screen and (min-width: 200px) and (max-width:450px) {
  .template-user-name {
    font-size: 4vw;
    font-weight: 600;
  }

  .template-img-text {
    font-size: 2.5vw;
    border-radius: 50%
  }

  .template-user-objective {
    font-size: 2vw;
  }


  .template-header-second {
    font-size: 1.6vw;
  }


  .template-user-details-comp {
    margin-left: 15px;
  }

  .template-image-comp {
    width: 20%;
    height: 20%;
  }

  .template-user-designation {
    font-size: 12px;
    font-weight: 600;
  }

  .template-profile-img {
    height: 60px;

  }

}




@media screen and (min-width: 450px) and (max-width:550px) {
  .template-user-name {
    font-size: 4vw;
    font-weight: 600;
  }

  .template-img-text {
    font-size: 2.5vw;
    border-radius: 50%
  }

  .template-user-objective {
    font-size: 2vw;
  }


  .template-header-second {
    font-size: 1.6vw;
  }


  .template-user-details-comp {
    margin-left: 15px;
  }

  .template-image-comp {
    width: 20%;
    height: 20%;
  }

  .template-user-designation {
    font-size: 12px;
    font-weight: 600;
  }

  .template-profile-img {
    height: 60px;

  }

}





@media screen and (min-width: 550px) and (max-width:700px) {
  .template-user-name {
    font-size: 4vw;
    font-weight: 600;
  }

  .template-img-text {
    font-size: 2.5vw;
    border-radius: 50%
  }

  .template-user-objective {
    font-size: 2vw;
  }


  .template-header-second {
    font-size: 1.6vw;
  }


  .template-user-details-comp {
    margin-left: 15px;
  }

  .template-image-comp {
    width: 20%;
    height: 20%;
  }

  .template-user-designation {
    font-size: 12px;
    font-weight: 600;
  }

  .template-profile-img {
    height: 60px;

  }

}





@media screen and (min-width: 700px) and (max-width:900px) {
  .template-user-name {
    font-size: 3vw;
    font-weight: 600;
  }

  .template-img-text {
    font-size: 2.5vw;
    border-radius: 50%
  }

  .template-user-objective {
    font-size: 1.5vw;
  }


  .template-header-second {
    font-size: 1.6vw;
  }


  .template-user-details-comp {
    margin-left: 15px;
  }

  .template-image-comp {
    width: 20%;
    height: 20%;
  }

  .template-user-designation {
    font-size: 12px;
    font-weight: 600;
  }

  .template-profile-img {
    height: 60px;

  }

}