.key-skills-form-fields {
  width: 100%;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  margin-bottom: 20px;
}

.key-skills-form-fields .key-input-with-delete {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.key-skills-form-fields .key-input-with-delete .MuiSvgIcon-root:hover {
  cursor: pointer;
}

.MuiPaper-root.MuiPaper-elevation.key-skills-paper {
  width: 75%;
  padding: 40px;
  margin-right: 0px;
  height: fit-content;
}

.MuiPaper-root.MuiPaper-elevation.key-skills-paper .key-skills-heading {
  font-size: 17px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  /* font-family: "Poppins", sans-serif; */
}

.skill {
  font-size: 12px;
  margin-left: -20px;
}

.MuiButtonBase-root.MuiButton-root.add-new-btn {
  text-transform: none;
  margin-bottom: 30px;
}

.MuiDivider-root.key-skills-divider {
  border-bottom-width: 2px;
  margin: 20px 0px;
}

@media screen and (max-width: 1010px) {
  .MuiPaper-root.MuiPaper-elevation.key-skills-paper {
    width: 100%;
    padding: 20px;
    font-size: 2.5vw !important;

  }
}

@media screen and (min-width: 1010px) and (max-width: 1300px) {
  .MuiPaper-root.MuiPaper-elevation.key-skills-paper {
    width: 90%;
    padding: 40px;

  }
}


@media screen and (min-width: 200px) and (max-width :450px) {
  .key-skills-form-fields {
    grid-template-columns: 1fr;

  }

  .skill {
    font-size: 2.2vw !important;
  }
}



@media screen and (min-width: 450px) and (max-width :550px) {
  .key-skills-form-fields {
    grid-template-columns: 1fr;

  }

  .skill {
    font-size: 2.2vw !important;
  }
}



@media screen and (min-width: 550px) and (max-width :900px) {
  .key-skills-form-fields {
    grid-template-columns: 1fr;

  }

  .skill {
    font-size: 1.5vw !important;
  }
}



@media screen and (min-width: 700px) and (max-width :900px) {
  .key-skills-form-fields {
    grid-template-columns: 1fr;

  }

  .skill {
    font-size: 1.2vw !important;
  }
}