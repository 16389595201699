


/* News detail section styling */
.news-detail {
    /* background-color: #f9f9f9; */
    /* padding: 20px; */
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.news-detail h1 {
    font-size: 2.2rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 20px;
}

.news-detail .text-muted {
    font-size: 0.9rem;
    color: #888;
}

.news-detail .lead {
    font-size: 1rem;
    color: #555;
}

.news-detail img {
    border-radius: 8px;
    max-height: 320px;
    object-fit: cover;
}

.news-detail p {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
}

/* Sidebar styling */
.sidebar {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar h3 {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 15px;
}

.sidebar .list-group {
    list-style-type: none;
    padding: 0;
}

.sidebar .list-group-item {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: space-between;
}

.sidebar .list-group-item a {
    text-decoration: none;
    /* color: #007bff; */
    color: black
}

.sidebar .list-group-item:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

.sidebar .list-group-item a:hover {
    color: #0056b3;
}

.sidebar .list-group-item span {
    font-size: 16px;
}


/* Responsive Design */
@media (max-width: 768px) {

    .col-lg-8,
    .col-md-7 {
        /* margin-bottom: 20px; */
    }
}