.fillDetails {
  width: 100%;
}

.details-filling {
  padding: 60px 0px;
}

@media screen and (max-width: 850px) {
  .details-filling {
    padding: 15px 0px;
  }

}

@media screen and (min-width: 850px) and (max-width: 1000px) {
  .details-filling-cont {
    grid-gap: 20px;
    padding: 40px 40px;
  }
}