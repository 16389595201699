.resume-builder-container-top {
  /* background-color: #f5f7fa; */
}

.resume-builder-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}

/* Styles for the resume preview section */
.resume-preview-section {
  display: flex;
  gap: 20px;
}

.resume-image {
  width: 210px;
  height: auto;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Styles for the resume info section */
.resume-info-section {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resume-info-section h1 {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 20px;
}

.resume-info-section p {
  font-size: 18px;
  margin-bottom: 30px;
  color: #555;
}

/* Styles for the buttons */
.buttons {
  display: flex;
  gap: 15px;
}

.create-resume-btn,
.resume-examples-btn {
  padding: 15px 25px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
}

.create-resume-btn {
  background-color: #951dc1;
  color: white;
  border: none;
}

.create-resume-btn:hover {
  background-color: #7d18a1;
}

.resume-examples-btn {
  background-color: transparent;
  color: #951dc1;
  border: 2px solid #951dc1;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .resume-builder-container {
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
  }

  .resume-preview-section {
    flex-wrap: wrap;
    justify-content: center;
  }

  .resume-image {
    width: 150px;
  }

  .resume-info-section {
    max-width: 100%;
    text-align: center;
  }

  .resume-info-section h1 {
    margin-top: 25px;
  }

  .buttons {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .resume-builder-container {
    padding: 20px 15px;
  }

  .resume-image {
    width: 120px;
  }

  .resume-info-section h1 {
    margin-top: 15px;
    font-size: 28px;
  }

  .resume-info-section p {
    font-size: 16px;
  }

  .create-resume-btn,
  .resume-examples-btn {
    padding: 12px 20px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .resume-builder-container {
    padding: 15px 10px;
  }

  .resume-image {
    width: 100px;
  }

  .resume-info-section h1 {
    margin-top: 15px;
    font-size: 24px;
  }

  .resume-info-section p {
    font-size: 14px;
  }

  .buttons {
    flex-direction: column;
    gap: 10px;
  }
}


/* -------------------------------------------seccccccccccccccccccccc555555555555 66666666666666666 */





/* App container */
.app-sec56 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  /* background-color: #651c81; */
  /* background-color: #eff2f9; */
  color: black;
  padding: 20px;
  /* Add padding for smaller screens */
}

/* Content wrapper */
.content56 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
}

/* Left-side text section */
.text-section56 {
  flex: 1;
}

.text-section56 h4 {
  color: orange;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.text-section56 h1 {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

.text-section56 p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
}

/* Buttons styling */
.buttons56 {
  display: flex;
  gap: 15px;
}

.primary-button56 {
  background-color: #951dc1;
  color: white;
  border: none;
  padding: 12px 28px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.secondary-button {
  background-color: transparent;
  color: #951dc1;
  border: 2px solid #951dc1;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.secondary-button56-2 {
  background-color: transparent;
  color: #951dc1;
  border: 2px solid #951dc1;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

/* Right-side resume section */
.resume-section56 {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.resume56 {
  position: absolute;
  width: 250px;
  height: auto;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.resume56-1 {
  bottom: -170px;
  left: 180px;
  transform: rotate(9deg);
}

.resume56-2 {
  bottom: -190px;
  left: 100px;
  transform: rotate(5deg);
  z-index: 1;
}

.resume56-3 {
  bottom: -190px;
  left: 0px;
  transform: rotate(-10deg);
  z-index: 2;
}

/* Responsive Design */

/* Adjust layout for smaller screens (1200px and below) */
@media (max-width: 1200px) {
  .content56 {
    flex-direction: column;
    align-items: center;
  }

  .resume-section56 {
    position: relative;
    justify-content: center;
    margin-top: 30px;
  }

  .resume56 {
    width: 200px;
    /* Reduce size of the resume images */
  }

  .resume56-1 {
    top: 10px;
    left: 80px;
  }

  .resume56-2 {
    top: 50px;
    left: -5px;
  }

  .resume56-3 {
    top: 100px;
    left: -100px;
  }

  .text-section56 h1 {
    font-size: 34px;
  }

  .text-section56 p {
    font-size: 14px;
  }
}

/* Tablet View (768px and below) */
@media (max-width: 768px) {
  .text-section56 h1 {
    font-size: 28px;
    text-align: center;
  }

  .text-section56 p {
    font-size: 14px;
    text-align: center;
  }

  .buttons56 {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .resume-section56 {
    justify-content: center;
  }

  .resume56 {
    width: 150px;
    /* Further reduce resume image size */
  }

  .resume56-1 {
    top: 0px;
    left: 60px;
  }

  .resume56-2 {
    top: 50px;
    left: -10px;
  }

  .resume56-3 {
    top: 100px;
    left: -100px;
  }
}

/* Mobile View (480px and below) */
@media (max-width: 480px) {

  .app-sec56 {
    min-height: 85vh;
    padding: 0px 20px;
  }

  .content56 {
    width: 100%;
    display: grid;
    margin-top: 100px;
  }

  .text-section56 h1 {
    font-size: 24px;
    text-align: center;
    margin-top: 100px;
  }

  .text-section56 p {
    font-size: 12px;
    text-align: center;
  }

  .buttons56 {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .resume-section56 {
    justify-content: center;
  }

  .resume56 {
    width: 130px;
  }

  .resume56-1 {
    top: -120px;
    left: 180px;
    transform: rotate(10deg);
  }

  .resume56-2 {
    top: -110px;
    left: 100px;
    transform: rotate(5deg);
    z-index: 1;
  }

  .resume56-3 {
    top: -120px;
    left: 20px;
    transform: rotate(-10deg);
    z-index: 2;
  }
}