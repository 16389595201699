.reviews-section {
  text-align: center;
  margin: 50px auto;
  width: 80%;
}

.reviews-section h2 {
  font-size: 40px;
  font-weight: bold;
}

.rating-first-div {
  width: 185px;
  border-radius: 10px;
  padding: 20px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}


.review-plus-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* d-flex justify-content-between align-items-center gap-5 */

.overall-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.rating-stars {
  margin-right: 20px;
}

.stars-rating-outoff {
  color: #00b67a;
  font-size: 32px;
}

.stars {
  color: #00b67a;
  font-size: 24px;
}

.rating-value {
  margin-left: 10px;
}

.font-b-sec-2 {
  font-size: 18px;
}

.trustpilot {
  display: flex;
  align-items: center;
}

.trustpilot img {
  width: 100px;
  margin-right: 10px;
}

.swiper-slide {
  /* display: flex; */
  /* justify-content: center; */
}

.review-card {
  width: 100%;
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); */
  text-align: left;
}

.reviewer-details {
  font-size: 12px;
  color: #777;
  margin-top: 10px;
}


/* Responsive Design starttttt-----------------------------------*/
@media (max-width: 1200px) {
  .reviews-section {
    text-align: center;
    margin: 50px auto;
    width: 80%;
  }

  .reviews-section h2 {
    font-size: 38px;
  }
}

@media (max-width: 768px) {
  .reviews-section {
    text-align: center;
    margin: 50px auto;
    width: 80%;
  }

  .reviews-section h2 {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .reviews-section {
    text-align: center;
    margin: 50px auto;
    width: 100%;
  }

  .reviews-section h2 {
    font-size: 24px;
  }

  .rating-first-div {
    width: 100%;
    margin-top: 20px;
  }

  .review-plus-slider {
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

/* Responsive Design endddddddd-----------------------------------*/

.swiper-button-prev {
  width: 45px;
  height: 45px;
  background-color: #951dc170;
  color: white;
  border-radius: 50%;
  transition: all .5s;
  margin-left: -10px;
}

.swiper-button-prev:hover {
  background-color: #951dc1;
  border: solid 2px #861cad;
  transition: all .5s;
}

.swiper-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: #951dc170;
  color: white;
  border-radius: 50%;
  transition: all .5s;
  margin-right: 12px;
}

.swiper-button-next:hover {
  background-color: #951dc1;
  border: solid 2px #861cad;
  transition: all .5s;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: 'prev';
  font-size: 18px;
  font-weight: 600;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: 'next';
  font-size: 18px;
  font-weight: 600;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: -14px var(--swiper-pagination-bullet-horizontal-gap, 4px);
  background-color: #951dc1;
}

/* custom button manage ------------------------------------------------ */
.custom-navigation {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-right: 15px;
}

.custom-navigation button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: #951dc170;
  color: white;
  border-radius: 50%;
  border: none;
  font-size: 22px;
  font-weight: 700;
  transition: all 0.5s;
  cursor: pointer;
}

/* Hover effect */
.custom-navigation button:hover {
  background-color: #951dc1;
  border: solid 2px #861cad;
  transition: all 0.5s;
}

/* Custom content inside buttons */
.custom-prev::after {
  content: "<";
}

.custom-next::after {
  content: ">";
}