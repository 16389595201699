.Items {
    padding-left: 10px;
}








/* create event tabs -------------------------------------------------------- */

.create-event-tab,
.create-event-active-tab {
    flex: 1;
    /* Make tabs evenly distribute in a row */
    text-align: center;
    padding: 14px 15px;
    border: 1px solid #951dc1;
    color: #951dc1;
    background-color: white;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.create-event-active-tab {
    color: white;
    background-color: #951dc1;
}

.create-event-tab:hover {
    background-color: #E9D1F2;
    transition: background-color 0.3s ease;
}

/* Responsive Container */
.tab-container {
    display: flex;
    flex-wrap: wrap;
    /* Allow tabs to wrap onto the next line */
    justify-content: center;
    /* Center tabs horizontally */
    gap: 5px;
    /* Space between tabs */
}

/* Media Queries for Smaller Screens */
@media (max-width: 992px) {

    .create-event-tab,
    .create-event-active-tab {
        flex: 1 1 30%;
        /* Tabs take 30% of the width on medium screens */
        font-size: 12px;
        padding: 8px 12px;
    }
}

@media (max-width: 768px) {

    .create-event-tab,
    .create-event-active-tab {
        flex: 1 1 45%;
        /* Tabs take 45% of the width on small screens */
        font-size: 12px;
        padding: 8px 10px;
    }
}

@media (max-width: 576px) {

    .create-event-tab,
    .create-event-active-tab {
        flex: 1 1 100%;
        /* Tabs take full width on extra small screens */
        font-size: 12px;
        padding: 8px;
    }
}