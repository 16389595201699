.resume-container {
    color: #333;
    /* margin: 3rem; */
    background-color: #eff2f9;
}

.header-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    max-width: 1250px;
    margin: 10px auto;
    padding: 50px 0px;
    gap: 2rem;
}

.illustration img {
    width: 500px;
    max-width: 500px;
    height: auto;
}

.text-content {
    /* text-align: center; */
    max-width: 500px;
}

.text-content h1 {
    font-size: 40px;
    color: #2b2d42;
    font-weight: bold;
}

.text-content p {
    margin: 1rem 0;
    font-size: 17px;
    line-height: 24px;
}

.buttons {
    display: flex;
    gap: 1rem;
}

.primary-button {
    background-color: #951dc1;
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    cursor: pointer;
    font-size: 1rem;
}

:hover.primary-button {
    background-color: #7d18a1;
}

.secondary-button {
    background-color: transparent;
    color: #951dc1;
    padding: 0.8rem 1.5rem;
    border: 1px solid #951dc1;
    cursor: pointer;
    font-size: 1rem;
}

.key-points-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
}

.key-point {
    /* text-align: center;
    max-width: 300px;
    flex: 1;
    margin: 1rem; */

    max-width: 300px;
    flex: 1;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    text-align: center; */
}

.key-point img {
    width: 80px;
    height: auto;
}

.key-point h4 {
    /* color: #2b2d42; */
    color: #8d41ab;
    margin: 1rem 0;
}

.key-point p {
    font-size: 1rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {}

@media (max-width: 768px) {

    .illustration img {
        width: 400px;
        max-width: 500px;
        height: auto;
    }


    .resume-container {
        padding: 2rem;
    }

    .header-section {
        flex-direction: column;
        align-items: center;
    }

    .text-content {
        text-align: center;
    }

    .key-points-section {
        flex-direction: column;
        align-items: center;
    }

    .key-point {
        max-width: 100%;
        margin: 1rem 0;
    }

    .buttons {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 480px) {
    .illustration img {
        width: 300px;
        max-width: 500px;
        height: auto;
    }


    .resume-container {
        padding: 1rem;
    }


    .text-content h1 {
        font-size: 1.5rem;
    }

    .text-content p {
        font-size: 1rem;
    }

    .primary-button,
    .secondary-button {
        padding: 0.6rem 1rem;
        font-size: 0.9rem;
    }

    .key-point {
        max-width: 100%;
        flex: 1;
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .key-point img {
        width: 60px;
        height: auto;
    }

    .header-section {
        padding: 0px
    }
}