.sec10-head {
    padding: 40px;
}

.sec10-head h2 {
    margin: 0 auto 64px;
    max-width: 864px;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
}

.sec10-main {
    display: flex;
    justify-content: space-around;
    max-width: 1250px;
    margin: 10px auto;
}

.sec10-video {
    width: 700px;
    height: auto;
}

.sec10-content {}


/* Container for the entire accordion */
.accordion-container {
    padding: 0px;
    width: 500px;
    margin: 0 auto;
}

/* Accordion Item */
.accordion-item {
    border-bottom: 1px solid #ddd;
}

/* Accordion Header */
.accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    padding: 15px 20px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.accordion-header:hover {
    background-color: #f2f2f2;
}

/* Chevron Icon */
.chevron {
    transition: transform 0.3s ease;
    font-size: 12px;
}

.chevron.rotate {
    transform: rotate(90deg);
}

/* Accordion Content */
.accordion-content {
    max-height: 0;
    overflow: hidden;
    padding: 0 20px;
    font-size: 14px;
    color: #555;
    background-color: #fafafa;
    transition: max-height 0.3s ease, padding 0.3s ease;
}

.accordion-content.open {
    max-height: 300px;
    /* Adjust for larger content */
    padding: 10px 20px;
}


@media (max-width: 1200px) {}

@media (max-width: 768px) {}


/* mobile screen ---------------------------------------------------------------------------------------------------- */
@media (max-width: 480px) {
    .sec10-head {
        padding: 20px;
    }

    .sec10-head h2 {
        margin: 0 auto 10px;
        /* max-width: 736px; */
        text-align: center;
        font-size: 24px;
        font-weight: bold;
    }

    .sec10-main {
        display: grid;
    }

    .sec10-video {
        width: 100%;
        height: auto;
    }

    .accordion-container {
        padding: 0px;
        width: 100%;
        margin: 20px auto;
    }

}