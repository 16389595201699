@media (max-width: 480px) {
    .login-left-section {
        height: 100vh;
        padding: 37px 40px 0 40px;
        object-fit: contain;
    }

    .right-login-form {
        padding: 0px 20px;
    }
}

@media (min-width: 481px) and (max-width: 992px) {
    .login-left-section {
        height: 100vh;
        padding: 37px 40px 0 60px;
        object-fit: contain;
    }

    .right-login-form {
        padding: 0px 80px;
    }
}

@media (min-width: 992px) {
    .login-section {
        height:100vh;
        background-image: url('../Images/loginRegister.svg');
        background-repeat: no-repeat;
        background-position: left;
        background-size: 50%;
        top:100px
    }

    .login-left-section {
        height: 100vh;
        padding: 37px 40px 0 130px;
        object-fit: contain;
    }

    .right-login-form {
        margin: 0px 80px;
        /* padding: 30px 20px;
      background-color: white;
      border-radius: 10px;
      box-shadow: rgba(179, 179, 179, 0.2) 0px 2px 8px 0px; */
    }
}

.login-left-section h3 {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 13px;
    color: #fff;
}

.login-left-section p {
    opacity: 0.75;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
}



.login-signup-btn {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    width: 100%;
    height: 50px;
    border: none;
    letter-spacing: normal;
    color: #fff;
    border-radius: 8px;
    background-image: linear-gradient(90deg, rgba(67, 0, 133, 1) 0%, rgba(164, 35, 204, 1) 0%, rgba(67, 0, 133, 1) 100%, rgba(164, 35, 192, 1) 100%) !important;
}

.already-have-an-acco {
    font-weight: 700;
    color: #2b2b2b;
    text-align: center;
    display: inherit;
    margin-top: 30px;
}

.register-btn {
    font-weight: 700;
    color: #6d1eb6;
    text-decoration: none;
}