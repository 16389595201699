.title {
    width: 100%;
    padding: 30px 70px;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-size: 22px;
    font-weight: 550;
    margin-top: 30px;
    letter-spacing: 0.4px;
    margin-bottom: 1px;

}


.stack {
    margin-top: -20px !important;
    padding-top: 1px !important;
}