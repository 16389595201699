.features-container {
  text-align: center;
  padding: 40px;
}

.features-container h1 {
  font-size: 40px;
  font-weight: bold;
  margin: 0 auto 60px;
  max-width: 736px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-item {
  /* background: #fff; */
  padding: 20px;
  /* border-radius: 10px; */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  text-align: left;
}

.sec3-logo-name-flex {
  display: flex;
  align-items: center;
  gap: 5px;
}

.feature-item span img {
  height: 50px;
}

.feature-item h3 {
  font-size: 20px;
  color: #282b8f;
  font-weight: 600;
  margin: 20px 0 10px;
}

.feature-item p {
  font-size: 16px;
  color: #707070;
  font-weight: 500;
}



/* Responsive Design starttttt-----------------------------------*/
@media (max-width: 1200px) {
  .features-container {
    padding: 15px;
  }

  .features-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .features-container {
    padding: 15px;
  }

  .features-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .features-container {
    padding: 15px;
  }

  .features-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  .features-container h1 {
    font-size: 24px;
    margin: 0 auto 10px;
  }

  .feature-item {
    padding: 10px;
    text-align: center;
  }


  .sec3-logo-name-flex {
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .sec3-logo-name-flex span {
    align-items: center;
    text-align: -webkit-center;
  }

  .feature-item h3 {
    font-size: 20px;
    color: #282b8f;
    font-weight: 600;
    margin: 7px 0 10px;
  }

}

/* Responsive Design endddddddd-----------------------------------*/