.warningModal {
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.445);
  padding: 20px;
  color: '#fff';
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning-mdl {
  width: 420px;
  padding: 20px;
  background-color: white !important;
  border-radius: 8px;
}

.warning-text {
    color: #f43254;
    font-weight: 600;
    font-size: 19px;
  }