.resume-builder-section-1 {
  background-color: #f7f9fc;
  padding: 40px 44px;
}


.resume-sec1-flex {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1250px;
  gap: 2rem;
}


.hero-resume-sec1-div1 {
  width: 45%;
}


.hero-resume-sec1-div2 {
  width: 50%;
}

.dot-class-resume-created {
  font-size: smaller;
  font-weight: 100;
}

.dot {
  display: inline-block;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.dot.hidden {
  opacity: 0;
}

.dot.visible {
  opacity: 1;
}

.sec1-title h1 {
  margin: 20px 0px;
  font-weight: bold;
}


.sec1-subtitle {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 16px;
}

.resume-sec1-btn {
  padding: 10px;
  background-color: #951dc1;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.vertical-line-sec1 {
  height: 60px;
  background-color: #000;
  border: 1px solid#bec4d5;
  margin-bottom: 10px;
}

.sec-1-39 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  padding: 4px;
  background-color: #e7f4ed;
  border-radius: 5px;
  color: #339d5d;
  font-size: 24px;
  font-weight: 400;
}

.sec-1-8 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  padding: 4px;
  background-color: #fff2cc;
  border-radius: 5px;
  color: #cf760d;
  font-size: 24px;
  font-weight: 400;
}

.sec1-39-8-text {
  align-self: stretch;
  color: #828ba2;
  font-size: 17px;
  line-height: 24px;
}


/* Background Sparkles  */
.sec-1_background-sparkles {
  position: relative;
  height: 500px;
  background-image: url('../Images/texture-56.webp');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

/* Input */
.sec-1_toolbar {
  position: absolute;
  top: 33px;
  left: 60%;
  transform: translateX(-50%);
  z-index: 1;
}

.sec-1_toolbar img {
  max-width: 350px;
  height: auto;
}

/* Resume Section */
.sec-1_resume {
  position: absolute;
  top: 5px;
  left: 70%;
  transform: translateX(-50%);
  z-index: 2;
}

.sec-1_resume img {
  max-width: 350px;
  height: auto;
}

/* frame_3 */
.sec-1_export-options {
  position: absolute;
  top: 90px;
  left: 88%;
  transform: translateX(-50%);
  z-index: 3;
}

.sec-1_export-options img {
  max-width: 150px;
  height: 80px;
}

/* badge*/
.sec-1_ai-writer {
  position: absolute;
  top: 190px;
  left: 87%;
  transform: translateX(-50%);
  z-index: 4;
}

.sec-1_ai-writer img {
  max-width: 100px;
  height: auto;
}

/* star_2 */
.sec-1_star_2 {
  position: absolute;
  top: 240px;
  left: 85%;
  transform: translateX(-50%);
  z-index: 5;
}

.sec-1_star_2 img {
  max-width: 50px;
  height: auto;
}


/* frame_2 */
.sec-1_frame_2 {
  position: absolute;
  bottom: 85px;
  left: 82%;
  transform: translateX(-50%);
  z-index: 5;
}

.sec-1_frame_2 img {
  max-width: 200px;
  height: auto;
}

/* star_1 */
.sec-1_star_1 {
  position: absolute;
  top: 10px;
  left: 25%;
  transform: translateX(-50%);
  z-index: 5;
}

.sec-1_star_1 img {
  max-width: 80px;
  height: auto;
}



/* star_5 */
.sec-1_star_5 {
  position: absolute;
  top: 100px;
  left: 36%;
  transform: translateX(-50%);
  z-index: 5;
}

.sec-1_star_5 img {
  max-width: 40px;
  height: auto;
}

/* Input */
.sec-1_frame_1 {
  position: absolute;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.sec-1_frame_1 img {
  max-width: 300px;
  height: auto;
}


/* ---------------------------------------- */
.trusted-logos {
  display: flex;
  flex-wrap: wrap; /* Default layout is flex */
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between items */
}

.trusted-logos img {
  width: 150px;
  margin: 5px;
}

.company-logo-div {
  font-size: 19px;
  text-align: center;
  font-weight: 500;
  margin-top: 20px;
}

/* Responsive Design starttttt-----------------------------------*/
@media (max-width: 1200px) {
  .resume-card {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .resume-card {
    flex-direction: column;
    width: 90%;
  }

  .resume-info {
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  .resume-details {
    width: 100%;
    padding: 15px;
  }

  .header h1 {
    font-size: 30px;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
}


/* mobile screen ---------------------------------------------------------------------------------------------------- */
@media (max-width: 480px) {
  .header h1 {
    font-size: 24px;
  }

  .header p {
    font-size: 0.9rem;
  }

  .resume-info img.profile-pic {
    width: 100px;
    height: 100px;
  }

  .trusted-logos img {
    width: 80px;
  }

  .resume-builder {
    padding: 10px;
  }

  /* sec1 mobile---------------------------------- 22/11/24 */
  .resume-sec1-flex {
    display: grid;
  }


  .hero-resume-sec1-div1 {
    width: 100%;
  }


  .hero-resume-sec1-div2 {
    width: 100%;
  }

  .resume-builder-section-1 {
    background-color: #f7f9fc;
    padding: 20px 20px;
  }


  .vertical-line-sec1 {
    height: 100px;
    background-color: #000;
    border: 1px solid#bec4d5;
    margin-bottom: 0px;
  }


  /* Background Sparkles  */
  .sec-1_background-sparkles {
    position: relative;
    height: 500px;
    background-image: url('../Images/texture-56.webp');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }

  /* Input */
  .sec-1_toolbar {
    position: absolute;
    top: 33px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .sec-1_toolbar img {
    max-width: 380px;
    height: auto;
  }

  /* Resume Section */
  .sec-1_resume {
    position: absolute;
    top: 18px;
    left: 60%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .sec-1_resume img {
    max-width: 290px;
    height: auto;
  }

  /* frame_3 */
  .sec-1_export-options {
    position: absolute;
    top: 90px;
    left: 85%;
    transform: translateX(-50%);
    z-index: 3;
  }

  .sec-1_export-options img {
    max-width: 150px;
    height: 80px;
  }

  /* badge*/
  .sec-1_ai-writer {
    position: absolute;
    top: 190px;
    left: 87%;
    transform: translateX(-50%);
    z-index: 4;
  }

  .sec-1_ai-writer img {
    max-width: 100px;
    height: auto;
  }

  /* star_2 */
  .sec-1_star_2 {
    position: absolute;
    top: 240px;
    left: 92%;
    transform: translateX(-50%);
    z-index: 5;
  }

  .sec-1_star_2 img {
    max-width: 50px;
    height: auto;
  }


  /* frame_2 */
  .sec-1_frame_2 {
    position: absolute;
    bottom: 85px;
    left: 72%;
    transform: translateX(-50%);
    z-index: 5;
  }

  .sec-1_frame_2 img {
    max-width: 200px;
    height: auto;
  }

  /* star_1 */
  .sec-1_star_1 {
    position: absolute;
    top: 20px;
    left: 7%;
    transform: translateX(-50%);
    z-index: 5;
  }

  .sec-1_star_1 img {
    max-width: 60px;
    height: auto;
  }


  /* star_5 */
  .sec-1_star_5 {
    position: absolute;
    top: 100px;
    left: 5%;
    transform: translateX(-50%);
    z-index: 5;
  }

  .sec-1_star_5 img {
    max-width: 40px;
    height: auto;
  }

  /* Input */
  .sec-1_frame_1 {
    position: absolute;
    bottom: 200px;
    left: 42%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .sec-1_frame_1 img {
    max-width: 280px;
    height: auto;
  }

  .trusted-logos {
    display: grid; /* Switch to grid layout */
    grid-template-columns: repeat(2, 1fr); /* 2 columns on small screens */
    gap: 10px; /* Adjust spacing */
  }

  .trusted-logos img {
    width: 120px; /* Reduce image size for smaller screens */
    margin: 0 auto; /* Center align items */
    object-fit: cover;
  }

  .company-logo-div {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}