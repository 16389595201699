.preview-header-title {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}


#main.active {
    filter: blur(5px)
}

.resume-preview-grid-container {
    width: 100%;
    /* display: grid; */
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.resume-preview-grid-item {
    width: 100%;
}

.resume-preview-grid-item .resume-save-container {
    width: 100%;
}

.resume-preview-grid-item .resume-save-container .resume-save-title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: rgb(99, 93, 93);
    margin-bottom: 0px;
}

.MuiFormControl-root.MuiTextField-root.resume-name-field {
    width: 50%;
    margin: 10px 0px 10px 0px;
}

.resume-preview-grid-item .resume-save-container .resume-back-next-container {
    width: 100%;
    display: flex;
    align-items: center;
}


@media screen and (max-width: 390px) {

    .resume-save-container {
        /* margin-top: 60vh; */
        /* margin-left: -95vw; */
    }

    .resume-save-title {
        /* width: 90%; */
    }

}

/* @media (min-width: 1536px) {
    .MuiPaper-root {
        height: 1000px !important;
        padding-bottom: 36px !important;
    }
}

@media (min-width: 1200px) {
    .MuiPaper-root {
        height: 1000px !important;
        padding-bottom: 36px !important;
    }
} */


@media (min-width: 0px) {
    .css-7340el-MuiPaper-root {
        height: 800px !important;
        padding-bottom: 36px !important;
    }
}