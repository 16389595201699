.expert-videos-first_div {
    background-color: #eff2f9;
    /* padding: 40px 0px; */
}

.expert-videos {
    padding: 40px;
    text-align: center;
    max-width: 1250px;
    margin: 0 auto;
    background-color: #eff2f9;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.header-buttons {
    display: flex;
    align-items: center;
}

.header h2 {
    font-size: 39px;
    margin-right: 20px;
    font-weight: bold;
}

.all-videos-btn {
    padding: 10px 20px;
    background-color: #951dc1;
    color: white;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
}

.all-videos-btn:hover {
    background-color: #861cad;
}

.swiper-buttons {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
}

.swiper-button-prev,
.swiper-button-next {
    padding: 10px 20px;
    background-color: rgba(149, 29, 193, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    background-color: #951dc1;
}

.swiper-button-prev {
    margin-left: 10px;
}

.swiper-button-next {
    margin-left: 10px;
}

.mySwiper {
    width: 100%;
    height: 100%;
}

.video-card {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); */
    transition: transform 0.3s ease;
}

.video-card img {
    height: 200px;
    cursor: pointer;
}

.video-card:hover {
    /* transform: translateY(-10px); */
}

.video-thumbnail {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.video-info {
    padding: 8px;
    text-align: left;
}

.video-info h4 {
    color: #1e2532;
    font-size: 22px;
    line-height: 28px;
}

.tags {
    align-items: center;
    color: #9fa6bb;
    display: flex;
    font-size: 16px;
    line-height: 18px;
    margin: 0px;
}



.swiper-pagination-bullet {
    background-color: #951dc1 !important;
    visibility: hidden;
}

.swiper-pagination-bullet-active {
    background-color: #861cad !important;

}


@media (max-width: 1200px) {}

@media (max-width: 768px) {}

@media (max-width: 480px) {
    .expert-videos-first_div {
        background-color: #eff2f9;
        padding: 10px 0px;
    }

    .expert-videos {
        padding: 10px;
    }

    .header h2 {
        font-size: 24px;
        font-weight: bold;
    }

    .all-videos-btn {
        padding: 8px 20px;
        background-color: #951dc1;
        color: white;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        border-radius: 5px;
    }


    .video-info h4 {
        color: #1e2532;
        font-size: 18px;
        line-height: 24px;
    }

    .tags {
        align-items: center;
        color: #9fa6bb;
        display: flex;
        font-size: 14px;
        line-height: 18px;
        margin: 0px;
    }

}