.Home {
  width: 100%;
}

/* ----------------------------top section start */

.template-card-container-1 {
  text-align: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
}

.template-card-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 12px;
}

.template-card-description {
  font-size: 1rem;
  color: #3f3c3c;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.template-card-btn {
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.template-card-btn:hover {
  background-color: #0056b3;
}

/* ----------------------------------------- */

/* .template-card-container-2 {
  text-align: center;
  background-color: #fff;
  padding: 0px 70px;
  border-radius: 12px;
}

.template-card-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: -1rem;
}

.template-card-menu-item {
  transition: color 0.3s ease;
  cursor: pointer;
  align-items: center;
  color: #828ba2;
  display: flex;
  font-weight: 700;
  letter-spacing: normal;
  margin-right: 64px;
  padding-bottom: 20px;
  text-transform: none;
  transition: color 0.15s;
  white-space: nowrap;
}

.template-card-icon {
  font-size: 24px;
}

.template-card-menu-item:hover {
  color: #951dc1;
}

.acitve-template-card-menu-item {
  transition: color 0.3s ease;
  cursor: pointer;
  align-items: center;
  color: #951dc1;
  display: flex;
  font-weight: 700;
  letter-spacing: normal;
  margin-right: 64px;
  padding-bottom: 20px;
  text-transform: none;
  transition: color 0.15s;
  white-space: nowrap;
}

.template-card-divider {
  border: none;
  border-top: 1px solid #63666d;
  margin: 0.5rem 0 1.5rem;
} */

.template-card-container-2 {
  text-align: center;
  background-color: #fff;
  padding: 20px 5vw;
  /* Use viewport width for better responsiveness */
  border-radius: 12px;
}

.template-card-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* Allow wrapping on smaller screens */
  gap: 10px;
  /* Adds space between buttons */
  margin-bottom: -1rem;
}

.template-card-menu-item,
.acitve-template-card-menu-item {
  transition: color 0.3s ease;
  cursor: pointer;
  align-items: center;
  color: #828ba2;
  display: flex;
  font-weight: 700;
  letter-spacing: normal;
  padding: 10px 15px;
  text-transform: none;
  transition: color 0.15s;
  white-space: nowrap;
  border: none;
  background: transparent;
}

.template-card-icon {
  font-size: 24px;
}

.template-card-menu-item:hover {
  color: #951dc1;
}

.acitve-template-card-menu-item {
  color: #951dc1;
}

.template-card-divider {
  border: none;
  border-top: 1px solid #63666d;
  margin: 0.5rem 0 1.5rem;
}

@media (max-width: 1024px) {
  .template-card-menu {
    /* justify-content: center; */
  }
}

@media (max-width: 768px) {
  .template-card-menu {
    /* flex-direction: column;*/
    /* align-items: center; */
    display: flex;
    justify-content: center;
  }

  .template-card-divider {
   margin-top: 20px;
  }

  .template-card-menu-item,
  .acitve-template-card-menu-item {
    /* margin-right: 0;
    width: 100%;
    justify-content: center; */
  }
}

@media (max-width: 480px) {
  .template-card-container-2 {
    padding: 0px 10px;
  }

  .template-card-container-1 {
    padding: 0px 10px;
  }

  .template-card-title {
    font-size: 25px;
  }
}


/* -------------------------------------------------------------------------------------top section end */
.home-templates-cont {
  width: 100%;
  padding: 30px 70px;
}

.template-header-title {
  font-size: 22px;
  font-weight: 550;
  margin-top: 10px;
  letter-spacing: 0.4px;
  margin-bottom: 1px;
}

.template-select-text {
  font-size: 12px;
  margin-bottom: 25px;
  opacity: 0.95;
}

.homeInfoWrapper .grid {
  display: flex;
  justify-content: center;
  padding: 50px;
  text-align: center;
  gap: 20px;
}

.item3 {
  word-wrap: wrap;
  position: relative;
}

.homeImage {
  width: 300px;
  height: 180px;
}

@media screen and (max-width: 450px) {
  .home-templates-cont {
    padding: 15px 30px;
  }
}

@media screen and (max-width: 900px) {
  .home-templates-cont {
    /* padding: 20px 55px; */
  }
}

.templates-img-cont {
  width: 100%;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 20px;
}

.templates-img-cont:hover {
  cursor: pointer;
}

.templates-img-cont:hover .DarkEffect {
  visibility: visible;
}

.template-img {
  width: 100%;
  height: 100%;
  background-color: #eff2f9;
  padding: 20px;
}

.MuiButtonBase-root.MuiButton-root.use-template-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  visibility: hidden;
  text-transform: capitalize;
  background-color: #951dc1;
  color: white;
  font-weight: 600;
}

.templates-img-cont:hover .MuiButtonBase-root.MuiButton-root {
  visibility: visible;
}

@media screen and (max-width: 450px) {
  .MuiPaper-root {
    width: 100% !important;
  }
}