.custom-phone-input{
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left:15px 20px 15px 48px !important;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 46px !important;
    width: 100% !important;
    outline: none;
}

.otp-verify-btn{
  background-color: rgb(37, 106, 253);
  color: white;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 3px;
  border: none;
  transition: all 0.5s;
}

.otp-verify-btn:hover{
    background-color: rgb(27, 98, 252);
}

.navlogo-signinpage{
    position: absolute;
    top: 20px;
}