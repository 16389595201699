.css-i22awe.use-template-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    visibility: hidden;
    text-transform: capitalize;
    background-color: #951dc1;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    padding: 6px
}

.templates-img-cont:hover .css-i22awe.use-template-btn {
    visibility: visible;
}