.DarkEffect {
    background-color: black;
    opacity: 0.4;
    visibility: hidden;
    z-index: 3;
    position: absolute;
    bottom: 0;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    /* border-radius: 20px; */
}