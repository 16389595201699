.faq-container {
  width: 65%;
  margin: auto;
  /* font-family: Arial, sans-serif; */
  text-align: center;
}

.faq-container h2 {
  font-size: 40px;
  font-weight: bold;
  margin: 0 auto 48px;
  /* max-width: 496px; */
}

.faq-item {
  border-top: 1px solid #ddd;
  padding: 20px 0;
  cursor: pointer;
}

.faq-question {
  font-size: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}

.faq-question-active {
  font-size: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #951dc1;
}

:hover.faq-question {
  color: #951dc1;
  transition: all 0.1s ease;
}

.faq-answer {
  padding: 10px 0;
  font-size: 18px;
  text-align: left;
  font-weight: 400;
}

.faq-answer span a {
  color: #951dc1;
}

.faq-footer {
  padding-top: 10px;
}

.faq-footer p {
  font-size: 14px;
  color: #666;
}

.faq-footer a {
  color: #951dc1;
  text-decoration: none;
}

.faq-footer a:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .faq-container {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .faq-container {
    width: 80%;
  }


  .faq-question {
    font-size: 16px;
    text-align: start;
  }


  .faq-question-active {
    font-size: 16px;
    text-align: start;
  }
}

@media (max-width: 480px) {
  .faq-container {
    width: 100%;
    padding: 20px;
  }

  .faq-container h2 {
    font-size: 24px;
    margin: 0 auto 18px;
  }

  .faq-question {
    font-size: 16px;
    text-align: start;
  }

  .faq-item {
    padding: 15px 0;
  }

  .faq-question-active {
    font-size: 16px;
    text-align: start;
  }
}