/* Container to hold the background and overlay text */
.sec11-background-container {
    position: relative;
    width: 100%;
    height: 60vh;
    background-color: #f0f0f0;
    overflow: hidden;
}


/* The falling image animation */
@keyframes imageFalling {
    0% {
        transform: translate(-50%, -100%) rotateY(0 deg);
        background-color: #f0f0f0;
    }

    100% {
        transform: translate(-50%, 100%) rotateY(360deg);
        background-color: #D8B6E1;
    }
}

/* Falling image style */
.sec11-falling-image {
    position: absolute;
    top: 300px;
    left: 50%;
    width: 180px;
    height: 240px;
    transform: translateX(-50%);
    animation: imageFalling 16s linear infinite;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.sec11-falling-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



/* The falling image animation */
@keyframes imageFalling1 {
    0% {
        transform: translate(-50%, -100%) rotateY(0 deg);
        background-color: #f0f0f0;
    }

    100% {
        transform: translate(-50%, 100%) rotateY(360deg);
        background-color: #D8B6E1;
    }
}

/* Falling image style */
.sec11-falling-image1 {
    position: absolute;
    top: 200px;
    left: 20%;
    width: 180px;
    height: 240px;
    transform: translateX(-50%);
    animation: imageFalling1 16s linear infinite;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.sec11-falling-image1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}





/* The falling image animation */
@keyframes imageFalling2 {
    0% {
        transform: translate(-50%, -100%) rotateY(0 deg);
        background-color: #f0f0f0;
    }

    100% {
        transform: translate(-50%, 100%) rotateY(360deg);
        background-color: #D8B6E1;
    }
}

/* Falling image style */
.sec11-falling-image2 {
    position: absolute;
    top: 100px;
    right: 10%;
    width: 180px;
    height: 240px;
    transform: translateX(-50%);
    animation: imageFalling2 16s linear infinite;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.sec11-falling-image2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}




/* The falling image animation */
@keyframes imageFalling3 {
    0% {
        transform: translate(-50%, -100%) rotateY(0 deg);
        background-color: #f0f0f0;
    }

    100% {
        transform: translate(-50%, 100%) rotateY(360deg);
        background-color: #D8B6E1;
    }
}

/* Falling image style */
.sec11-falling-image3 {
    position: absolute;
    top: 70px;
    left: 0%;
    width: 180px;
    height: 240px;
    transform: translateX(-50%);
    animation: imageFalling3 16s linear infinite;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.sec11-falling-image3 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* The falling image animation */
@keyframes imageFalling4 {
    0% {
        transform: translate(-50%, -100%) rotateY(0 deg);
        background-color: #f0f0f0;
    }

    100% {
        transform: translate(-50%, 100%) rotateY(360deg);
        background-color: #D8B6E1;
    }
}

/* Falling image style */
.sec11-falling-image4 {
    position: absolute;
    top: 450px;
    left: 65%;
    width: 180px;
    height: 240px;
    transform: translateX(-50%);
    animation: imageFalling4 16s linear infinite;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.sec11-falling-image4 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* The falling image animation */
@keyframes imageFalling5 {
    0% {
        transform: translate(-50%, -100%) rotateY(0 deg);
        background-color: #f0f0f0;
    }

    100% {
        transform: translate(-50%, 100%) rotateY(360deg);
        background-color: #D8B6E1;
    }
}

/* Falling image style */
.sec11-falling-image5 {
    position: absolute;
    top: 80px;
    left: 35%;
    width: 180px;
    height: 240px;
    transform: translateX(-50%);
    animation: imageFalling1 16s linear infinite;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.sec11-falling-image5 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* ----------------------------------------------------------------------------------- */



/* Overlay text style */
.sec11-overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    text-align: center;
    margin: 0 auto;
}

.sec11-overlay-text h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;

}

.sec11-overlay-text p {
    font-size: 15px;
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 24px;
}

.sec11-overlay-text h1,
.sec11-overlay-text p {
    padding: 10px;
    border-radius: 8px;
}


.home-letters__buttons {
    align-items: center;
    display: flex;
    justify-content: center;
}


.home-letter-btn1 {
    appearance: none;
    background-color: #951dc1;
    border-radius: 4px;
    border-width: 0;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    outline: none;
    padding: 14px 24px;
    text-align: center;
}

:hover.home-letter-btn1 {
    background-color: #6e118f;
}

.home-letter-btn2 {
    appearance: none;
    border-radius: 4px;
    border-width: 0;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    outline: none;
    padding: 14px 24px;
    text-align: center;
    color: #951dc1;
    border: 1px solid #951dc1;
}

:hover.home-letter-btn2 {
    color: #6e118f;
    border: 1px solid #6e118f;
}


@media (max-width: 1200px) {}

@media (max-width: 768px) {}


/* mobile screen ---------------------------------------------------------------------------------------------------- */
@media (max-width: 480px) {
    .sec11-background-container {
        position: relative;
        width: 100%;
        height: 65vh;
    }


    .sec11-overlay-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
        text-align: center;
        margin: 0 auto;
        /* max-width: 544px; */
    }

    .sec11-overlay-text h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 27px;
        width: 400px;
    }

    .sec11-overlay-text p {
        font-size: 15px;
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: 24px;
        padding: 0 25px;
    }

}