/* Main Container */
.pricing-section {
    text-align: center;
    padding: 50px;
}

/* Header Section */
.pricing-header {
    background-color: #651c81;
    color: white;
    padding: 70px 20px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.pricing-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.pricing-header p {
    font-size: 1rem;
    margin: 0;
}

/* Cards Container */
.pricing-cards-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: -38px;
    padding: 0 20px;
}

/* Individual Card */
.pricing-card {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 300px;
    padding: 30px;
    text-align: center;
    margin-bottom: 30px;
}

.card-header img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    border-radius: 50%;

}

.card-header h2 {
    color: #651c81;
    font-size: 1.5rem;
}

.card-header h3 {
    font-size: 1.2rem;
    color: #651c81;
    margin: 10px 0;
}

.card-details {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.card-details li {
    font-size: 0.9rem;
    color: #5d6573;
    margin-bottom: 5px;
}

/* Subscribe Button */
.subscribe-btn {
    background-color: #651c81;
    color: white;
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
}

.subscribe-btn:hover {
    background-color: #511768;
}

@media (max-width: 768px) {
    .pricing-cards-container {
        flex-direction: column;
        align-items: center;
    }

    .pricing-card {
        width: 90%;
    }

    .pricing-section {
        text-align: center;
        padding: 15px;
    }
}