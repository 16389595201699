.radio-btn-label{
    font-size: 14px;
}

.astrik-icon{
    color: red;
}

.profilePhoneInput{
    width: auto !important;
    height: 45px !important;
}