.resume-section-9 {
    padding: 20px;
    background-color: #fff;
}

.resume-section-9 h1 {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
}

.content-9 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-container-9 {
    margin: 20px auto;
    max-width: 560px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.video-container-9 {
    width: 80%;
    max-width: 1000px;
    margin: auto;
    aspect-ratio: 16 / 9;
    position: relative;

}

.video-container-9 iframe {
    width: 100%;
    height: 100%;
    border: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 16px;
}

.description-9 {
    margin: 20px auto;
    font-size: 17px;
    line-height: 24px;
    color: #555;
    max-width: 600px;
    text-align: center;
}


.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    /* Adjust spacing as needed */
}

.button-container-9 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    /* Adjust spacing as needed */
}

.cta-button-9 {
    background-color: #951dc1;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 10px;
}

.cta-button-9:hover {
    background-color: #761898;
}



@media (max-width: 1200px) {}

@media (max-width: 768px) {}

@media (max-width: 480px) {
    .resume-section-9 h1 {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    .description-9 {
        font-size: 16px;
        line-height: 24px;
        color: #555;
        max-width: 600px;
        text-align: center;
    }
    
    .video-container-9 {
        width: 100%;
        max-width: 1000px;
        margin: auto;
        aspect-ratio: 16 / 9;
        position: relative;
    
    }
}